@media (max-width: 790px) {
    body {
        background-image: url('assets/background-2.jpg');
        background-size: center;
    }
    .deleteCatTitleBtn,
    .deleteBoxWrapper,
    .deleteFocusTitleBtn {
        opacity: 1;
    }
}

@media (max-width: 575px) {
    .time {
        @include headingStyle($xlarge-media-font);
    }
    .date {
        @include headingStyle($medium-font);
    }
}

@media (max-width: 479px) {
    .catTitle {
        width: 65%;
    }
}

@media (max-width: 457px) {
    .time {
        font-size: $large-media-font;
    }

    .focusTitle {
        margin: 20px;
    }

    .focusTitleInput {
        width: 250px;
        font-size: $medium-font;
    }

    .focusForm h2 {
        margin-bottom: 23px;
        max-width: 50%;
    }

    .categoryWrapper {
        width: 90%;
        margin: 10px auto;
    }

    .categoryBoxWrapper {
        margin-left: 0;
        margin-right: 0;
    }

    .catTitleWrapper {
        width: 100%;
    }

    .catCheckBox.btns {
        margin-left: 10px;
    }

    .deleteCatTitleBtn {
        width: 20px;
    }

    .newCatBtn.btns {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 335px) {
    .date {
        font-size: $small-font;
    }

    .focusForm h2 {
        font-size: $medium-font;
        margin-left: 0;
        margin-right: 0;
    }
}

html {
    font-size: 62.5%;
}

body {
    background-image: url('assets/background-1.jpg');
    background-attachment: fixed;
    background-size: cover;
}

:focus,
button:focus {
    outline-color: transparent;
    outline-style: none;
}

// Disable webkit yellow background for autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 50000s ease-in-out 0s;
}

// Standardize placeholder color
input::placeholder {
    color: gray;
}

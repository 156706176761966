.btns {
    @include buttonStyles;
}

.categoryWrapper {
    width: 85%;
    max-width: 1550px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.catBoxColor0 {
    background-color: #cbc0d3;
}
.catBoxColor1 {
    background-color: #efd3d7;
}

.catBoxColor2 {
    background-color: #dee2ff;
}

.catBoxColor3 {
    background-color: lighten(#8e9aaf, 15);
}

.categoryBoxWrapper {
    margin: 20px;
    width: 350px;
    min-height: 300px;
    opacity: 0.8;
    @include containerShadow(lighten($primary-font-color, 20));

    &:hover {
        @include hoverShadow(darken($primary-font-color, 20));
    }

    &:hover .deleteCatTitleBtn,
    &:hover .deleteBoxWrapper {
        opacity: 1;
    }

    &:hover .catTitleInput {
        border-bottom: 1px solid gray;
    }
}

.categoryBox {
    width: 100%;
}

.deleteBoxWrapper {
    text-align: right;
    opacity: 0.5;
}

.catTitle {
    @include textStyle($medium-font);
    overflow-wrap: break-word;
    display: inline-block;
    width: 232.5px;
    color: $primary-font-color;
    text-align: center;
}

.catTitleWrapper {
    text-align: left;
    margin: 50px 0 0 0;
}

.catCheckBox {
    @include inputTextStyle($small-font);
    vertical-align: top;
    margin-left: 20px;
}

.deleteCatTitleBtn {
    width: 30px;
    opacity: 0;
    vertical-align: top;
    padding: 0;
}

.newCatBtn.btns {
    width: 350px;
    height: 300px;
    margin: 20px;

    font-size: $medium-font;
    color: $accent-color;
    background: rgb(255, 255, 255);
    opacity: 0.7;

    @include containerShadow(lighten($primary-font-color, 20));

    &:hover {
        @include hoverShadow(darken($primary-font-color, 20));
    }
}

.new-task-msg {
    @include headingStyle($xsmall-font);
    margin-top: 10px;
}

.catTitleInput {
    @include inputTextStyle($medium-font);
    width: 80%;
    // margin: 10px;
    margin: 50px 0 0 20px;
    font-family: $primary-font;
    font-weight: 700;
    color: $primary-font-color;
}

.strikedOut {
    text-decoration: line-through;
}

@mixin buttonStyles($padding...) {
    padding: $padding;
    margin: 10px;
    border: none;
    background: none;
    font-size: $small-font;
}

@mixin textStyle($font-size) {
    font-family: $primary-font;
    font-size: $font-size;
}

@mixin headingStyle($font-size) {
    font-family: $secondary-font;
    font-size: $font-size;
}

@mixin inputTextStyle($font-size) {
    font-size: $font-size;
    border: none;
    background: none;
}

@mixin containerShadow($color) {
    box-shadow: 0px 0px 15px 2px $color;
}

@mixin hoverShadow($color) {
    box-shadow: 0px 0px 15px 3px $color;
}

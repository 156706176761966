.time,
.date {
    display: block;
}

.time {
    @include headingStyle($xlarge-font);
}

.date {
    @include headingStyle($medium-font);
}

.focusTitle {
    @include headingStyle($medium-font);
    margin-bottom: 20px;
}

.focusTitleInput {
    @include inputTextStyle($large-font);
    width: 400px;
    border-bottom: 2px solid black;
    text-align: center;
    font-family: $secondary-font;
    font-weight: bolder;
}

.focusForm h2 {
    display: inline-block;
    @include headingStyle($large-font);
    max-width: 55%;
    overflow-wrap: break-word;
    line-height: 1;
    margin: 0 20px;
}

.focusCheckboxButton {
    width: 50px;
    vertical-align: top;
}

.deleteFocusTitleBtn {
    opacity: 0;
    width: 50px;
    vertical-align: top;
}

.focusTitleWrapper {
    &:hover .deleteFocusTitleBtn {
        opacity: 1;
        cursor: pointer;
    }
}

.strikedOut {
    text-decoration: line-through;
}

.congratsMsg {
    @include inputTextStyle($xsmall-font);
}

$primary-font: 'Amatic SC', cursive;
$secondary-font: 'Quicksand', sans-serif;

$primary-font-color: darken(#212529, 20);
$accent-color: darken(#90ee90, 40);

$xsmall-font: 1.8rem;
$small-font: 2.4rem;
$medium-font: 3.2rem;
$large-font: 4.5em;
$xlarge-font: 9rem;

// Media Query
$xlarge-media-font: 6rem;
$large-media-font: 5rem;

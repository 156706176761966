@import 'styles/bootstrap_reset.css';
@import './styles/partials/setup';
@import './styles/partials/global';
@import './styles/partials/variables';
@import './styles/partials/mixins';
@import './styles/partials/dateTime';
@import './styles/partials/focus';
@import './styles/partials/category';
@import './styles/partials/Nova';
@import './styles/partials/mediaquery';

.App {
    text-align: center;
    padding-top: 2rem;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.login-container,
.logout-container {
    text-align: right;
    margin: 0 20px 50px 0;
}

.login-btn,
.logout-btn {
    padding: 5px 15px;
    border: none;
    @include headingStyle(1.2rem);
    @include containerShadow(lighten($primary-font-color, 20));
    margin-bottom: 8px;
    font-weight: bolder;
}

.logout-msg,
.login-msg {
    @include headingStyle(1.3rem);
    font-weight: bolder;
}
